<template>
  <slot name="top"></slot>
  <ion-refresher
    slot="fixed"
    @ion-refresh="doRefresh($event)">
    <ion-refresher-content
      :pulling-icon="chevronDownCircleOutline"
      refreshing-spinner="circles"
      refreshing-text="로딩중..." />
  </ion-refresher>
  <ion-list lines="full">
    <ion-item-sliding>
      <!-- TODO: accordion 개발작업 필요 -->
      <template v-if="total > 0">
        <ion-item
          v-for="(item, index) in list.datas"
          :key="index"
          :detail="detailIcon"
          @click="handleDetail(item)">
          <slot
            name="contents"
            :item="item"></slot>
        </ion-item>
      </template>
      <template v-else-if="total === 0">
        <ion-item class="nodata">
          <p>목록이 없습니다.</p>
        </ion-item>
      </template>
      <template v-else>
        <ion-item>
          <ion-label>
            <h2>
              <ion-skeleton-text
                animated
                style="width: 60%" />
            </h2>
            <h3>
              <ion-skeleton-text
                animated
                style="width: 60%" />
            </h3>
            <p>
              <ion-skeleton-text
                animated
                style="width: 100%" />
            </p>
          </ion-label>
        </ion-item>
      </template>
    </ion-item-sliding>
  </ion-list>
  <ion-infinite-scroll
    @ion-infinite="loadData($event)"
    threshold="100px"
    id="infinite-scroll"
    :disabled="!nextLoading">
    <ion-infinite-scroll-content
      loading-spinner="bubbles"
      loading-text="로딩중..." />
  </ion-infinite-scroll>
</template>

<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import {
  IonRefresher,
  IonRefresherContent,
  IonList,
  IonItemSliding,
  IonItem,
  IonLabel,
  IonSkeletonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/vue'
import { chevronDownCircleOutline } from 'ionicons/icons'
import { defineProps, defineEmits, reactive, watch, onMounted } from 'vue'
  
//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const props = defineProps({
  name: { type: String, default: undefined },
  datas: { type: Array, default: () => [] },
  total: { type: Number, default: 0 },
  pageNum: { type: Number, default: 0 },
  accordion: { type: Boolean, default: false },
  nextLoading: { type: Boolean, default: true },
  detailIcon: { type: Boolean, default: true }
})
  
const emit = defineEmits(['nextPage', 'detail'])

const list = reactive({
  datas: []
})

const pushData = () => {
  if (props.nextLoading) {
    emit('nextPage', Number(props.pageNum) + 1)
  }
}

const loadData = (ev) => {
  setTimeout(() => {
    if (props.nextLoading) {
      pushData()
    }
    ev.target.complete()
  }, 500)
}

const doRefresh = (event) => {
  setTimeout(() => {
    event.target.complete()
  }, 2000)
}

const handleDetail = (item) => {
  emit('detail', item)
}

watch(() => props.datas, (newVal) => {
  list.datas = newVal
}, { immediate: true, deep: true })

onMounted(() => {
  pushData()
})

//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------

</script>

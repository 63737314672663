<template>
  <vp-base-layout
    page-title="수거대상목록"
    page-default-back-link="/pickup">
    <vp-template-list
      :datas="datas"
      :total="pageInfo.total"
      detail-icon="false">
      <template #top>
        <ion-list lines="full">
          <ion-list-header>
            <ion-label>{{ agencyName }}</ion-label>
          </ion-list-header>
          <ion-item>
            <ion-label>수거목록 (총 {{ $filters.countTxt(pageInfo.total) }})</ion-label>
            <ion-icon
              :icon="funnelOutline"
              @click="presentActionSheet"
              slot="end" />
          </ion-item>
        </ion-list>
      </template>
      <template #contents="{ item }">
        <ion-label>
          <h2>{{ item.title }}</h2>
          <p>{{ item.description }}</p>
          <p v-if="orderYn">
            <vp-button
              fill="outline"
              color="danger"
              @click="onClickCancel(item)">
              취소
            </vp-button>
            <vp-button @click="onDetail(item)">
              수거하기
            </vp-button>
          </p>
        </ion-label>
      </template>
    </vp-template-list>
  </vp-base-layout>
</template>
<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import { IonListHeader, IonList, IonItem, IonLabel, IonText, IonIcon, IonCheckbox, useIonRouter, toastController } from '@ionic/vue'
import { funnelOutline } from 'ionicons/icons'
import { useStore } from 'vuex'
import { ref, inject, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import VpTemplateList from '@/components/templates/list/VpTemplateList.vue'
import { getDept } from '@/api/permission/dept'
import { getOrders } from '@/api/purchase/purchase'
import { getPurchaseOrder, delPurchaseCancel, getOrderStateYn } from '@/api/dealer/Receipt'
import { actionSheetController } from '@ionic/vue'
//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const store = useStore()
const fixed = ref(true)
const dayjs = inject('$dayjs')
const ionRouter = useIonRouter()
const route = useRoute()

const datas = ref([])

const pageInfo = ref({
  total: 0,
  pageNum: 1,
  pageSize: 99999
})

const prtnrinfo = ref({})
const agencyName = ref(undefined)
const orderYn = ref(true)

const getDeptInfo = async (id) => {
  const agency = await getDept(id)
  agencyName.value = agency.data.deptName
}

const getOrderList = async (query) => {
  const orders = await getOrders(query)
  orders.data.results.forEach((obj) => {
    let orderStateTxt = undefined
    switch (Number(obj.order_state_code)) {
      default:
        orderStateTxt = '접수'  
        obj.description = dayjs(obj.order_date).format('YYYY-MM-DD A hh:mm:ss')
    }

    let title = []
    if (obj.order_nm) {
      title.push(obj.order_nm)
    }
    if (obj.order_tel_no) {
      title.push(obj.order_tel_no)
    }
    if (orderStateTxt) {
      title.push(orderStateTxt)
    }

    obj.title = title.join(' / ')
  })
  

  datas.value = orders.data.results.filter((item) => Number(item.dept_belong_id) === Number(route.params.id) && Number(item.order_state_code) === 1)
  // pageInfo.value.total = orders.data.count
  pageInfo.value.total = datas.value.length
}

//취소하기
const onClickCancel = async(item) => {
  const cancelParams = ref({
    id: item.id
  })
  const res = await delPurchaseCancel(cancelParams.value)
  await getOrderList(pageInfo.value)
}

//접수하기
const onDetail = async (item) => {
  const response = await getPurchaseOrder(item.id)
  prtnrinfo.value = response.data
  store.dispatch('Receipt/setPrtnrInfo', prtnrinfo.value)
  ionRouter.push('/input-imei')
}

//접수상태코드 사용여부
const orderstateYn = async () => {
  const stateYn = await getOrderStateYn()
  if(stateYn.data.length === 0){
    orderYn.value = false
  }
}

////// 필터부분 추가 시작 - 임시 ///////
const presentActionSheet = async () => {
  const actionSheet = await actionSheetController.create({
    header: '선택한 접수상태 목록만 보여집니다.',
    //subHeader: '선택한 항목만 보여집니다',
    buttons: [
      
      {
        text: '접수',
        data: {
          action: '1'
        }
      },
      {
        text: '수거',
        data: {
          action: '2'
        }
      },
      {
        text: '완료(입고)',
        data: {
          action: '3'
        }
      },
      {
        text: 'Cancel',
        role: 'cancel',
        data: {
          action: 'cancel'
        }
      }
    ]
  })

  await actionSheet.present()
  const res = await actionSheet.onDidDismiss()

  if(res.role != 'cancel') {
    const param = pageInfo
    param.value.order_state_code = res.data.action
    await getOrderList(param.value)
  }
}

//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------
onMounted(async () => {
  await getDeptInfo(route.params.id)
  await getOrderList(pageInfo.value)
  await orderstateYn()
})
</script>
